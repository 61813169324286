export const availablePlans = {
    "BASIC": {
        "id": "namsorapi_v2_BASIC",
        "duplicateNamesRatio": 0,
        "price": 0,
        "stripeId": {
            "EUR": "plan_EJ0aH3AaUGR2t2",
            "GBP": "plan_EJ0aRPOuUXAarL",
            "USD": "plan_EJ0a8sDY9QIgPu"
        },
        "quota": 1000,
        "name": "BASIC",
        "commitmentMonth": 3,
        "priceOverage": 0.005,
        "hardLimitMax": 200000 // 1000€
    },
    "ENTERPRISE": {
        "id": "namsorapi_v2_ENTERPRISE",
        "duplicateNamesRatio": 20,
        "price": 5000,
        "stripeId": {
            "EUR": "plan_EJ0atVb9xFXafD",
            "GBP": "plan_EJ0a2jU82QUbqu",
            "USD": "plan_EJ0aOoWYrOdxok"
        },
        "quota": 10000000,
        "name": "ENTERPRISE",
        "commitmentMonth": 3,
        "priceOverage": 0.0005,
        "hardLimitMax": 100000000
    },
    "MEGA": {
        "id": "namsorapi_v2_MEGA",
        "duplicateNamesRatio": 20,
        "price": 999,
        "quota": 1000000,
        "name": "MEGA",
        "commitmentMonth": 3,
        "priceOverage": 0.001,
        "hardLimitMax": 10000000,
        "stripeId": {
            "USD": "plan_EJ0aBYV7Dcb02p",
            "EUR": "plan_EJ0aV0V8NW4s9E",
            "GBP": "plan_EJ0a5wJAdmOAoC"
        }
    },
    "PRO": {
        "id": "namsorapi_v2_PRO",
        "duplicateNamesRatio": 0,
        "price": 19,
        "stripeId": {
            "EUR": "plan_EJ0aYQ8rEzSt5K",
            "GBP": "plan_EJ0ah1KT0LaZ9X",
            "USD": "plan_EJ0aClEk0lqVTf"
        },
        "quota": 10000,
        "name": "PRO",
        "commitmentMonth": 3,
        "priceOverage": 0.003,
        "hardLimitMax": 500000 // 1500€
    },
    "ULTRA": {
        "id": "namsorapi_v2_ULTRA",
        "duplicateNamesRatio": 10,
        "price": 130,
        "stripeId": {
            "EUR": "plan_EJ0a2A0CXvcgjp",
            "GBP": "plan_EJ0aoSrGvAo55d",
            "USD": "plan_EJ0aEuuP8oJJY7"
        },
        "quota": 100000,
        "name": "ULTRA",
        "commitmentMonth": 3,
        "priceOverage": 0.002,
        "hardLimitMax": 2000000 // 4000€
    }
};