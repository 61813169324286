import { useMemo } from "react";
import axios from "axios";
import { Flash } from "../functions/Flash";
import { verifyCard } from "../functions/utils";

import { useInitUser as lang } from "../../lang/utils/user-hooks-and-provider/lang";
import { availablePlans } from "../list/availablePlans";



export const useInitUser = ({firebase, userData, dispatchUserData}) => {


    //----------------- Refresh token if expired -----------------//

    const refreshToken = useMemo(() => {
        return async () => {
            if(userData?.user?.stsTokenManager?.expirationTime < Date.now()){
                const newToken = await firebase?.refreshUserToken();
                return newToken || userData?.user?.accessToken;
            }else{
                return userData?.user?.accessToken;
            }
        }
    }, [firebase, userData?.user?.stsTokenManager?.expirationTime, userData?.user?.accessToken]);



    //------------- Get API version -------------//

        useMemo(() => {
            if(userData?.apiVersion) return;

            axios.get('https://v2.namsor.com/NamSorAPIv2/api2/json/softwareVersion')
            .then(res => {
                dispatchUserData({
                    type: "update",
                    values: {
                        apiVersion: res.data?.softwareVersion?.join(".")
                    },
                    isInit: true
                });
            })
            .catch(err => {
                dispatchUserData({
                    type: "update",
                    values: {
                        apiVersion: "2.0.31"
                    },
                    isInit: true
                });
            })
        }, [userData?.user?.uid]);

        
        

    //------------- Get available plans && Update credits to purchase -------------//

       useMemo(() => {
            if(userData?.availablePlans && userData?.user?.uid) return;

                const values = {
                    availablePlans,
                };

                const selectedPlanName = window.localStorage.getItem("selectedPlan");
                if(selectedPlanName){
                    values.selectedPlan = availablePlans[selectedPlanName];
                };

                const creditsToPurchase = window.localStorage.getItem("creditsToPurchase");
                if(creditsToPurchase){
                    values.creditsToPurchase = creditsToPurchase;
                };

                dispatchUserData({
                    type: "update",
                    values,
                    isInit: true
                });
        }, [userData?.user?.uid]);




    //------------- Get user data -------------//

    const getUserData = useMemo(() => {

        return async () => {
            const userToken = await refreshToken();

            await axios.post("/api/get/user-data", {
                userToken,
                uid: userData?.user?.uid,
                email: userData?.user?.email
            })
            .then(res => {
                dispatchUserData({
                    type: "update",
                    values: {
                        ...res.data
                    },
                    isInit: true
                });
            })
            .catch(error => {
                Flash({msg: lang.error_message.user_data, state: "error"});
                if(typeof window.gtag === "function"){
                    window.gtag('event', 'get', {
                        'event_category' : 'userData',
                        'event_result': "Error",
                        'error_msg': error
                    });
                };
            });
        }
    }, [refreshToken, userData?.user?.uid, userData?.user?.email]);




    //------------- Set global infos -------------//

    useMemo(() => {

        if(userData?.userApiKey){

            const { lastPeriod, userPlan, paymentMethods, userApiKey, customer, invoices } = userData;

            //Calcul de la quantité d'overage et du prix d'overage
            if(userPlan && lastPeriod && userPlan !== "error" && lastPeriod !== "error" && userApiKey && userApiKey !== "error" && userPlan && userPlan !== "error"){
                const overageDiscountRatio = 1 - (Number(userApiKey?.data?.coupons?.[userPlan?.id]?.overage?.percentOff || 0) / 100);
                const overageExclTax = Math.round(lastPeriod?.overage.usage * lastPeriod?.overage.costPerCredit * overageDiscountRatio * 100) / 100;
                lastPeriod.overageExclTax = overageExclTax;
            };

            //Trouver le mode de paiement par défault
            if(paymentMethods && customer && paymentMethods !== "error" && customer !== "error"){
                paymentMethods?.map(card => {
                    if(card.id === customer.defaultPaymentMethod){
                        card.defaultCard = true;
                    }else{
                        card.defaultCard = false;
                    }
                });
            }

            //------------------- Set Global data -------------------//
                const isBasicPlan = userPlan?.name === "BASIC";
                const isApiKeyDisabled = userApiKey?.data?.disabled;
                const isRejectedCharge = invoices !== "error" ? invoices?.some(({charge, total, attemptedCount, status}) => (charge?.status === "failed" && status !== "void") || (attemptedCount >=5 && status === "open" && total > 0)) : null;
                const isRejectedOrPendingCharge = invoices !== "error" ? invoices?.some(({charge, attempted, status, total}) => (["pending", "failed"].includes(charge?.status) && status !== "void" && total > 0) || (attempted && status === "open" && total > 0)) : null;
                const rejectedCharges = invoices !== "error" ? invoices?.filter(({charge, total, attempted, status}) => (["pending", "failed"].includes(charge?.status) && status !== "void" && total > 0) || (attempted && status === "open" && total > 0)) : null;

                const isHardLimitReached = userApiKey?.data?.hardLimit && lastPeriod?.overage?.usage >= userApiKey?.data?.hardLimit;

                const apiKeyDisableReason = 
                userApiKey?.data?.disableReason?.abuse ? "abuse" : 
                userApiKey?.data?.disableReason?.unpaidInvoice ? "unpaidInvoice" :
                userApiKey?.data?.disableReason?.unverified ? "unverified" : 
                userApiKey?.data?.disableReason?.limitReached && isHardLimitReached ? "hardLimit" : 
                userApiKey?.data?.disableReason?.limitReached ? "quota" : 
                userApiKey?.data?.disableReason?.pausedSubscription ? "pausedSubscription" :
                ""; //Raison de la désactivation

                const isThereCb = paymentMethods !== "error" && Boolean(paymentMethods?.length); //On vérifie qu'il y a au moins une carte
                const isThereValidCb = Boolean(isThereCb && paymentMethods && paymentMethods?.filter(card => { //On vérifie qu'il y a au moins une carte valide (date d'expiration non dépassée)
                    if(card?.defaultCard){
                        return verifyCard(card);
                    }
                }).length);
                const isThereDefaultCb = Boolean(isThereCb && paymentMethods && paymentMethods?.filter(card => card?.defaultCard).length); //On vérifie qu'il y a au moins une carte par défault




            //---------------- Set Billing & Invoice info ----------------//
               const lastCharge = invoices?.[0]?.charge;


            //---------------- Set ready info ----------------//

                const testIfDataReady = values => {
                    if(values.some(value => userData[value] === "error")){
                        return "error";
                    };
                    return values.every(value => Boolean(userData[value]));
                };

                dispatchUserData({
                    type: "update",
                    values: {
                        globalInfos: {
                            apiKeyDisableReason,
                            isApiKeyDisabled,
                            isBasicPlan,
                            isRejectedCharge,
                            isRejectedOrPendingCharge,
                            isThereCb,
                            isThereValidCb,
                            isThereDefaultCb,
                            lastCharge,
                            testIfDataReady
                        },
                        customerInfos:{
                            name: customer?.name,
                            email: customer?.email,
                            phone: customer?.phone,
                            vatId: customer?.vatTaxData?.value,
                            vatTaxData: customer?.vatTaxData,
                            ...customer?.address
                        }
                    },
                    isInit: true
                });
        }
    }, [
        userData?.lastSubscription, userData?.lastPeriod, userData?.userPlan, userData?.paymentMethods, 
        userData?.userApiKey, userData?.customer, userData?.invoices
    ]);




    //------------- Init User data -------------//

    useMemo(() => {
        if(userData?.user?.uid && userData?.user?.email){
            getUserData()
        }
    }, [userData?.user?.uid, userData?.user?.email, userData?.updateKey]);


    return {
        getUserData,
        refreshToken
    };

};